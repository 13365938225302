var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('van-nav-bar',{staticClass:"cart-title",attrs:{"fixed":"","left-arrow":""},on:{"click-left":_vm.onBack,"click-right":function($event){_vm.isDelete = !_vm.isDelete}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" 收货地址编辑 ")]},proxy:true}])}),_c('div',{staticClass:"address"},[_c('van-address-edit',{attrs:{"area-list":_vm.areaList,"show-delete":"","show-set-default":"","show-search-result":"","area-columns-placeholder":['请选择', '请选择', '请选择'],"address-info":{
        name: _vm.info.name,
        tel: _vm.info.tel,
        addressDetail: _vm.info.addressDetail,
        areaCode: _vm.info.areaCode,
        postalCode: _vm.info.postalCode,
        isDefault: _vm.info.isDefault,
      }},on:{"save":_vm.onSave,"delete":_vm.onDelete}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }