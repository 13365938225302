<template>
  <div>
    <van-nav-bar class="cart-title" fixed left-arrow @click-left="onBack" @click-right="isDelete = !isDelete">
      <template #title>
        收货地址管理
      </template>
    </van-nav-bar>

    <div class="address">
      <van-address-list
        v-model="chosenAddressId"
        :list="addressList"
        default-tag-text="默认"
        @add="onAdd"
        @edit="onEdit"
      />

    </div>
  </div>
</template>

<script>

export default {
  name: 'AddressManagement',
  metaInfo: {
    title: '收货地址管理', //设置页面title
    meta: [{                 //设置页面meta
      name: 'keyWords',
      content: '关键词'
    },
    {
      name: "description",
      content: "描述",
    },
    ]
  },
  data() {
    return {

      isAddress: true,
      uname: sessionStorage.getItem("uname"), //获取用户名
      chosenAddressId: "1",

      addressList: [
        {
        id: '1',
        name: '张三',
        tel: '13000000000',
        address: '浙江省杭州市西湖区文三路 138 号东方通信大厦 7 楼 501 室',
        isDefault: true,
      },
      {
        id: '2',
        name: '李四',
        tel: '1310000000',
        address: '浙江省杭州市拱墅区莫干山路 50 号',
      },
      {
        id: '3',
        name: '王五',
        tel: '1310000000',
        address: '河北省廊坊市固安县新昌西街56号（一通教育）',
      },
      ],
      addressDisabledList: [
        {
        id: '4',
        name: '趙六',
        tel: '1320000000',
        address: '河北省廊坊市固安县新昌西街56号（一通教育）',
        }
      ]

    }
  },
  created() {

  },

  methods: {

    onAdd() {
      this.$router.push("/addressEdit");
    },
    //点击编辑传地址id给地址编辑，以便地址回显
    onEdit(item, index) {
      let id = this.list[index].id;
      this.$router.push('addressEdit?id=${id}');
    },

    onBack() {
      history.back();
    },
  },
  components: {

  },
  computed: {

  },
}
</script>
 
<style lang = "less" scoped>
.address {
  padding: 7rem 1rem 1rem 1rem;
}

.van-submit-bar {
  bottom: 0rem;
  border-top: 0.1rem solid #e7e9ed;
}


</style>