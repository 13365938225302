<template>
  <div class="bg">
    <div v-if="isGood">
      <van-nav-bar class="cart-title" fixed left-arrow @click-left="onBack" @click-right="isDelete = !isDelete">
        <template #title>
          购物车<span class="productNum">（共{{ cartTotal.checkedGoodsCount }}商品）</span>
        </template>
        <template #right>
          <!-- {{ isDelete ? "完成" : "编辑" }} -->

          <div v-if="select">
            <div class="edit" @click="onEdit">编辑</div>
          </div>
          <div v-if="!select">
            <div class="edit" @click="onOk">完成</div>
          </div>
        </template>
      </van-nav-bar>
      <!-- <Tips /> -->

      <van-checkbox-group v-model="result" class="cart-checkbox">
        <van-checkbox checked-color="#ee0a24" @click="changeChecked(item)" :name="item.id" v-for="item in cartList"
          :key="item.id">
          <van-swipe-cell>
            <van-card class="shop-card" tag="执业药师" desc="描述信息在这里显示" :title="item.goods_name" :thumb="item.list_pic_url">
              <template #price>
                <div class="cart-price"><span>￥</span>{{ item.retail_price }}</div>
              </template>
            </van-card>
            <div @click.stop.prevent>
              <div v-if="select">
                <van-stepper @change="stepperChange(item)" v-model="item.number" />
              </div>

              <div v-if="!select">
                <van-button round icon="delete-o" size="small" class="delete-cart"
                  color="linear-gradient(to right,#ff6034,#ee0a24)" @click="delGood(item.product_id)">删除</van-button>
              </div>
            </div>
          </van-swipe-cell>
        </van-checkbox>
      </van-checkbox-group>

      <van-submit-bar :price="cartTotal.checkedGoodsAmount * 100">
        <van-checkbox checked-color="#ee0a24" v-model="checkedAll">全选</van-checkbox>
        <template #button>
          <van-button class="submit-cart" color="linear-gradient(to right,#ff6034,#ee0a24)"  @click="onSubmit">提交订单</van-button>
        </template>
      </van-submit-bar>

    </div>

    <div v-if="!isGood">
      <van-nav-bar class="cart-title" fixed left-arrow @click-left="onBack">
        <template #title>
          购物车
        </template>
      </van-nav-bar>
      <van-empty class="cart-van-empty" description="您还没有添加任何商品哦"
        image="http://cdnoss.ksyt.com.cn/wxImages/no-course.png" image-size="80%">
        <van-button round type="danger" class="bottom-button">选课中心</van-button>
      </van-empty>
    </div>
  </div>
</template>

<script>

// import Tips from '@/components/Tips'
import { GetCartListData, ChangeGoodChecked, StepperChangeData, DeleteGood } from '@/request/api'
export default {
  name: 'Cart',
  metaInfo: {
    title: '购物车', //设置页面title
    meta: [{                 //设置页面meta
      name: 'keyWords',
      content: '关键词'
    },
    {
      name: "description",
      content: "描述",
    },
    ]
  },
  data() {
    return {
      // 展示选中的商品的数组
      // 哪一个商品的checked的值为1,就可以把它的id添加到这个数组
      result: [],
      // checkedAll: true,

      // 购物车列表数据
      cartList: [],
      // 商品总信息
      cartTotal: [],
      active: '',
      select: true,
      isDelete: false,
      isGood: true

    }
  },
  created() {
    GetCartListData().then(res => {
      if (res.data.errno == 0) {
        //  console.log(res.data);
        this.totalFn(res.data.data)

      }
    })
  },

  methods: {
    // editBtn() {
    //   this.isEditing = this.isEditing
    // },
    totalFn(data) {
      this.cartList = data.cartList
      this.cartTotal = data.cartTotal
      //  console.log(this.cartTotal);
      this.result = []
      this.cartList.map(item => {
        item.checked == 1 ? this.result.push(item.id) : ""
      })
      // 购物车为空的时候显示空的状态
      if (this.result.length == 0) {
        // console.log(1)
        this.isGood = false
      } else {
        console.log(this.result)
      }
    },
    onClickEditAddress() { },
    onSubmit() { this.$router.push('/order') },
    changeChecked(item) {
      // if (this.isShow) return
      // 总结: 为什么要发送这个请求?这个请求有何作用
      // 这个请求告诉了后端我们改变了哪些值,后端接收之后,返回给我们一个处理过后的完整的页面数据
      // 包括:总结的计算,我们只需要拿到这个数据给页面填写即可
      ChangeGoodChecked({
        isChecked: item.checked == 0 ? 1 : 0,
        productIds: item.product_id,
      })
        .then(res => {
          if (res.data.errno == 0) {
            console.log(res.data);
            this.totalFn(res.data.data)
          }
        })
    },
    stepperChange(item) {
      StepperChangeData({
        // isChecked: item.checked = 1,
        goodsId: item.goods_id,
        id: item.id,
        number: item.number,
        productId: item.product_id
      })
        .then(res => {
          if (res.data.errno == 0) {
            console.log(res.data);
            this.totalFn(res.data.data)
          }
        })
    },
    //点击编辑
    onEdit() {
      this.select = false
    },

    //点击完成
    onOk() {
      this.select = true
      if (this.result.length == 0) {
        console.log(1)
        this.isGood = false
      } else {
        console.log(this.result)
      }
    },
    //删除
    del() {
      if (this.result.length == this.$store.state.cart.length) {
        this.$store.state.cart.splice(0, this.result.length)
        this.result.splice(0, this.result.length)
      } else {
        this.$store.state.cart.forEach(item => {
          if (item.flag) {
            this.$store.state.cart.splice(item, 1)
            this.result.splice(item.id, 1)
          }
        })
      }
    },

    delGood(pid) {
      DeleteGood({ productIds: pid.toString() }).then(res => {
        if (res.data.errno == 0) {
          this.totalFn(res.data.data)
        }
      })
    },

    onBack() {
      history.back();
    },
    userKefu() {
      this.$router.push({ path: '/user' });
    }
  },
  components: {
    // Tips
  },
  computed: {
    checkedAll: {
      get() {
        return this.result.length == this.cartList.length
      },
      set(val) { // 即将要修改成的新值
        // if (this.isShow) return

        // 处理id数组
        let ids_arr = [];
        this.cartList.map(item => {
          ids_arr.push(item.product_id)
        })

        ChangeGoodChecked({
          isChecked: val ? 1 : 0,
          productIds: ids_arr.join(),
        })
          .then(res => {
            if (res.data.errno == 0) {
              console.log(res.data);
              this.totalFn(res.data.data)
            }
          })
      }

    }
  },
}
</script>
 
<style lang = "less" scoped>

.van-button--square {
  height: 100%;
}

.van-stepper {
  text-align: right;
  position: absolute;
  right: 0rem;
  bottom: -0.3rem;
}

/deep/.van-checkbox__label {
  flex: 1;
}

.van-checkbox-group {
  background-color: #fff;
  // padding-bottom: 1.5rem;

  .van-checkbox {
    border-top: 1.5rem solid #e7e9ed;
    padding: .1rem .15rem;
  }
}

.cart-title .productNum {
  color: #666666;
  font-size: 2rem;
}

.cart-checkbox {
  margin: 6rem 0 7rem 0;
}

.van-submit-bar {
  bottom: 0rem;
  border-top: 0.1rem solid #e7e9ed;
}

// .tips {
//   line-height: 6rem;
//   text-align: center;
//   margin-top: 6rem;
// }

.collection-cart {
  margin: 0 2rem;
}
</style>