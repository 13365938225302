<template>
  <div>
    <van-nav-bar class="cart-title" fixed left-arrow @click-left="onBack" @click-right="isDelete = !isDelete">
      <template #title>
        收货地址编辑
      </template>
    </van-nav-bar>

    <div class="address">
      <van-address-edit :area-list="areaList" show-delete show-set-default show-search-result
        :area-columns-placeholder="['请选择', '请选择', '请选择']" @save="onSave" @delete="onDelete" :address-info="{
          name: info.name,
          tel: info.tel,
          addressDetail: info.addressDetail,
          areaCode: info.areaCode,
          postalCode: info.postalCode,
          isDefault: info.isDefault,
        }" />

    </div>
  </div>
</template>

<script>

import { areaList } from '@vant/area-data';

export default {
  name: 'AddressManagement',
  metaInfo: {
    title: '收货地址编辑', //设置页面title
    meta: [{                 //设置页面meta
      name: 'keyWords',
      content: '关键词'
    },
    {
      name: "description",
      content: "描述",
    },
    ]
  },
  data() {
    return {

      areaList,
      info: [],
      isAddress: true,
      uname: sessionStorage.getItem("uname"), //获取用户名
      chosenAddressId: "1",

    }
  },
  created() {

  },

  methods: {

    onBack() {
      history.back();
    },
    onSave(content) {
      this.isDefault = content.isDefault ? "1" : "0"; //数据库该项设置为0，1，如不需修改可删除该行
      //如果地址id不存在，则连接新增地址接口，存在则连接修改地址接口
      if (!this.$route.query.id) {
        //发送请求连接新增地址接口
        this.axios
          .post(
            "/addAddress",
            `uname=${this.uname}&name=${content.name}&phone=${content.tel}&province=${content.province}&city=${content.city}&county=${content.county}&detail_addr=${content.addressDetail}&group_message=${this.isDefault}&areaCode=${content.areaCode}&postalCode=${content.postalCode}`
          )
          .then((res) => {
            console.log(res);
          });
      } else {
        //发送请求连接修改地址接口
        this.axios
          .post(
            "/updateAddress",
            `id=${this.$route.query.id}&name=${content.name}&phone=${content.tel}&province=${content.province}&city=${content.city}&county=${content.county}&detail_addr=${content.addressDetail}&group_message=${this.group_message}&areaCode=${content.areaCode}&postalCode=${content.postalCode}`
          )
          .then((res) => {
            console.log(res);
          });
      }
      this.$toast.success("保存成功");
      //返回地址列表页，强制刷新
      this.$router.replace("/addresslist");
      location.reload();
    },
    onDelete() {
      let id = this.$route.query.id;
      //发送请求连接删除地址接口
      this.axios.post("/delAddress?id=" + id).then((res) => {
        console.log(res.data);
      });
      this.$toast.success("删除成功");
      this.$router.go(-1);
    },
  },
  components: {

  },
  computed: {

  },
}
</script>
 
<style lang = "less" scoped>
.address {
  padding: 7rem 1rem 1rem 1rem;
}

.van-submit-bar {
  bottom: 0rem;
  border-top: 0.1rem solid #e7e9ed;
}
</style>