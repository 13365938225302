<template>
  <div class="tips">
      <van-row>
        <van-col span="8"><van-icon class="tips-icon" name="checked" />7天无理由退款</van-col>
        <van-col span="8"><van-icon class="tips-icon" name="checked" />满400元减50元</van-col>
        <van-col span="8"><van-icon class="tips-icon" name="checked" />购买即送试卷</van-col>
      </van-row>
  </div>
</template>

<script>
export default {
  data () {
    return {

 
    }
  }
}
</script>
 
<style lang = "less" scoped>
  .tips {
    line-height: .3rem;
    text-align: center;
    .van-icon {
      transform: translate(-.02rem,.02rem);
    }
  }
  .tips-icon {
    color: #ee0a24;
  }
</style>