<template>
  <div>
    <van-nav-bar class="cart-title" fixed left-arrow @click-left="onBack" @click-right="isDelete = !isDelete">
      <template #title>
        我的订单
      </template>
    </van-nav-bar>
    <div style="padding: 6rem 0 22rem 0;">
      <van-tabs v-model:active="active" class="orderList">
        <van-tab title="待支付">
        <div style="padding-top: 1rem;">
          <div class="order-title">
            <van-cell center title="订单号：PL1658481840RN182" />
          </div>
          <div class="shopList1" v-for="(item, index) in shopList" :key="index">
            <van-card class="shop-card" :tag="item.goods_tag" :title="item.goods_title" :thumb="item.goods_thumb"
              @click="handleClickCourseDetail">
              <template #tags>
                <div class="tags">
                  <van-tag plain round type="danger">7天退款</van-tag>
                </div>
              </template>
              <template #bottom>
                <span class="price">￥{{ item.goods_price }}</span>
              </template>
            </van-card>
          </div>
          <div class="order-price">
            <van-cell center>
              <template #title>
                <span style="font-size:1.8rem; color:#656466">去支付查看优惠详情</span>
              </template>
              <template #extra>
                <van-button round type="danger" plain hairline size="small" style="margin-right: 0.6rem;">取消订单</van-button>
                <van-button round type="danger" size="small">去支付</van-button>
              </template>
            </van-cell>
          </div>
        </div>
        </van-tab>
        <van-tab title="已支付">
        <div style="padding-top: 1rem;">
          <div class="order-title">
            <van-cell center title="订单号：PL1658481840RN182" />
          </div>
          <div class="shopList1" v-for="(item, index) in shopList" :key="index">
            <van-card class="shop-card" :tag="item.goods_tag" :title="item.goods_title" :thumb="item.goods_thumb"
              @click="handleClickCourseDetail">
              <template #tags>
                <div class="tags">
                  <span style="font-size: 1.6rem;line-height:3rem;">课程有效期：2023-08-31 00:00:00</span>
                </div>
              </template>
              <template #bottom>
                <span class="price">￥{{ item.goods_price }}</span>
              </template>
            </van-card>
          </div>
          <div class="order-price">
            <van-cell center>
              <template #title>
                实际支付：<span class="price">￥980.00</span>
              </template>
              <template #extra>
                <van-button round type="danger" plain hairline size="small" style="margin-right: 0.6rem;">申请售后</van-button>
                <van-button round type="danger" size="small">已完成</van-button>
              </template>
            </van-cell>
          </div>
        </div>

        </van-tab>
      </van-tabs>


    </div>
  </div>
</template>

<script>

// import Tips from '@/components/Tips'
import { GetCartListData, ChangeGoodChecked, StepperChangeData, DeleteGood } from '@/request/api'
export default {
  name: 'Cart',
  metaInfo: {
    title: '我的订单', //设置页面title
    meta: [{                 //设置页面meta
      name: 'keyWords',
      content: '关键词'
    },
    {
      name: "description",
      content: "描述",
    },
    ]
  },
  data() {
    return {
      // 展示选中的商品的数组
      // 哪一个商品的checked的值为1,就可以把它的id添加到这个数组
      result: [],
      // checkedAll: true,
      isAddress: true,

      // 购物车列表数据
      cartList: [],
      // 商品总信息
      cartTotal: [],
      active: '',
      checked: false,
      showList: false,
      shopList: [
        {
          goods_tag: "执业药师",
          goods_title: "2022年中药学专业知识一（精品班）",
          goods_thumb: "https://cdnoss.ksyt.com.cn/weixin/banci.png",
          goods_price: 980.00
        }
      ],

    }
  },
  created() {

  },

  methods: {
    addressManagement() { this.$router.push('/addressManagement') },
    onSubmit() { },
    handleClickCourseDetail() { },

    onBack() {
      history.back();
    },
    onChange(index) { 
      this.showList = false;
      this.chosenCoupon = index;
    },
    onExchange() {
      this.coupons.push(coupon);
      console.log(code);
     },
    chosenCoupon() { },
    // disabledCoupons(){},
  },
  components: {
    // Tips
  },
  computed: {

  },
}
</script>
 
<style lang = "less" scoped>

.shopList1 {
  margin: 0 1rem;
}

.shopList1 .shop-card {
  padding: 1.5rem 1rem !important;
}

.van-submit-bar {
  bottom: 0rem;
  border-top: 0.1rem solid #e7e9ed;
}


.collection-cart {
  margin: 0 2rem;
}

.order-title {
  height: 5rem !important;
  margin: 0 1rem;
  line-height: 4rem;
}

.order-title .van-cell {
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}

.order-title .van-cell .van-cell__title {
  color: #333;
  font-size: 2rem;
  padding: 0 1.5rem;
  font-weight: 600;
}

.order-price {
  height: 4rem !important;
  margin: 0 1rem;
  border-top: 0.1rem solid #e7e9ed;
}

.order-price .van-cell {
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  padding: 0 1.5rem;
  font-size: 2rem;
}

.order-price .van-cell .price {
  padding: 0rem;
  font-size: 2.4rem;
  height: 3rem;
  line-height: 3rem;
  font-weight: 600;
  color: #ee0a24;
}

</style>